.pre-room-page-container {
	display: flex;
	flex-direction: column;
	width: max-content;
	gap: 8px;
	align-items: center;
	justify-content: center;
	box-sizing: border-box;
	height: 100%;
	width: 100%;
	padding: 24px;
	/* border-radius: 16px; */
	/* box-shadow: 0px 0px 20px 0px rgba(83, 83, 255, 0.5); */
}

.pre-room-page {
	color: white;
	background: gray;
	display: flex;
	position: relative;
	flex-direction: column;
	width: max-content;
	justify-content: center;
	align-items: center;
	text-align: center;
	padding: 32px;
	margin: 0px 200px;
	margin-bottom: 150px;
}

.pre-room-welcome-text {
	max-width: 500px;
}

.pre-room-audio-test {
	width: 100%;
	margin: 10px;
}

.pre-room-question-form-btn {
	display: flex;
	justify-content: center;
}

.pre-room-questions {
	display: flex;
	flex-direction: column;
	justify-content: left;
}

.pre-room-question-answer {
	width: 100%;
}

.pre-room-audio-test {
	display: flex;
	justify-content: center;
}

.sound-test {
	display: grid;
	place-items: center;
	font-size: 16px;
	width: 100%;
}

.options {
	display: flex;
	justify-content: center;
	align-items: center;
	margin-bottom: 16px;
}
