.module-player {
    position: relative;
    display: grid;
}

.module-player>* {
    background-color: white;
    box-sizing: border-box;
}

.carla-speech-container {
    background: white;
    position: absolute;
    top: -100px;
    box-sizing: border-box;
    border-radius: 16px;
    border: solid 1px cyan;
    padding: 16px;
    box-shadow: 0px 0px 20px 0px rgba(83, 83, 255, 0.5);
}

.module-section-container {
    max-width: 60vw;
}

.screen {
    height: 100%;
    width: 100%;
    background-color: gray;
    color: white;
    padding: 32px;
    box-sizing: border-box;
}

.room-carla {
    bottom: -100px;
    top: auto;
}