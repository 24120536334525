#background-stars {
    position: fixed;
    right: 0;
    bottom: 0;
    min-width: 100%; 
    min-height: 100%;
}

.invalid-container {
    width: 100%;
    height: 100%;
    display: grid;
    place-items: center;
}