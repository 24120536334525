#background-stars {
	position: fixed;
	right: 0;
	bottom: 0;
	min-width: 100%;
	min-height: 100%;
}

.room-box {
	display: grid;
	/* grid-template-columns: 1fr 2fr; */
	align-items: center;
	justify-items: center;
	padding: 24px;
	box-sizing: border-box;
	width: 100%;
}

.room-header {
	z-index: 100;
	height: 100%;
	background: gray;
	color: white;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 0px 35px;
}

.room-display-name {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.room-other-user > img {
	filter: brightness(2) saturate(0.5);
}

.room-display-name > img {
	height: 50px;
	width: 50px;
}

.roompage-container {
	height: 100%;
	width: 100%;
	display: grid;
	grid-template-rows: 100px auto;
}

.call-controls {
	position: fixed;
	display: flex;
	z-index: 100;
	top: 20px;
	gap: 8px;
	place-items: center;
	box-sizing: border-box;
	left: 50%;
	transform: translateX(-50%);
}

.call-controls {
	background-color: gray;
	color: white;
}

.room-box > * {
	width: max-content;
}
