.feedback-page-container {
    height: 100%;
    width: 100%;
    padding: 24px;
    box-sizing: border-box;
    display: grid;
    place-items: center;
    overflow: hidden;
}

.feedback-screen {
    background: gray !important;
    color: white !important;
    position: relative;
    padding: 32px;
    max-width: 60vw !important;
    min-height: "150px";
	display: grid;
	place-items: center;
}

.feedback-controls {
    padding: 12px 0px 0px 0px;
    display: flex;
    justify-content: flex-end;
}

.feedback-submit {
    padding: 12px 0px 0px 0px;
    display: flex;
    justify-content: center;
}

.feedback-content > img {
    display: block;
    margin-left: auto;
    margin-right: auto;
    height: 300px;
	width: 300px;
}

.countdown {
    font-size: 36px;
    text-align: center;
}

.feedback-rating-stars {
    display: flex;
    justify-content: center;
    padding: 12px;
}

.feedback-text {
    display: flex;
    justify-content: center;
    padding: 18px;
    -webkit-text-fill-color: #fff;
}