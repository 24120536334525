.homepage-container {
    /* box-shadow: 0px 0px 20px 0px rgb(0 200 247 / 50%);
    position: absolute;
    padding: 24px;
    border-radius: 16px;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    width: max-content;
    height: max-content;
    background-color: gray; */
    height: 100%;
    width: 100%;
}