@import url(https://fonts.googleapis.com/css?family=PT+Mono:regular&subset=latin,latin-ext);
.initializer {
	display: flex;
	justify-content: center;
	align-items: center;
}

.dashboard-container {
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	grid-gap: 8px;
	gap: 8px;
	align-items: center;
	justify-content: center;
	padding: 24px;
	border-radius: 16px;
	box-sizing: border-box;
	overflow: hidden;
}

.dashboard {
	box-sizing: border-box;
	padding: 16px;
	height: -webkit-max-content;
	height: max-content;
	box-sizing: border-box;
	background: gray;
	color: white;
	position: relative;
}

.dashboard-bubble-body {
	background-color: gray;
	max-width: 60vw;
	min-height: 150px;
	padding: 32px;
	display: grid;
	place-items: center;
	height: -webkit-max-content;
	height: max-content;
}

.dashboard-bubble-body > div {
	display: grid;
	place-items: center;
}

.question-body-text {
	font-size: 32px;
	padding: 12px;
	box-sizing: border-box;
}

.question-body-image-container {
	display: grid;
	grid-template-columns: repeat(4, auto);
	justify-content: center;
	flex-wrap: wrap;
	max-width: 60vw;
	grid-gap: 12px;
	gap: 12px;
	/* max-height: 300px; */
}

.question-body-image {
	max-height: 150px;
	border-radius: 16px;
	overflow: hidden;
	max-width: 150px;
	min-width: 150px;
	min-height: 150px;
	background: lightgray;
	cursor: pointer;
}

.dashboard-session-btn {
	display: flex;
	justify-content: center;
	margin: 10px;
}

.dashboard-header {
	height: 90px;
	background: gray;
	color: white;
	display: flex;
	justify-content: left;
	align-items: center;
	padding: 0px 35px;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
}

.dashboard-display-name {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: 24px;
	height: 100%;
}

.dashboard-display-name > img {
	height: 50px;
	width: 50px;
}

.dashboard-page-container {
	height: 100%;
	width: 100%;
	padding: 24px;
	box-sizing: border-box;
	display: grid;
	place-items: center;
	overflow: hidden;
}

.dashboard-screen {
	background: gray !important;
	color: white !important;
	position: relative;
	padding: 32px;
	max-width: 60vw !important;
	display: grid;
	place-items: center;
}

@media screen and (max-width: 1280px) {
	.question-body-image {
		max-width: 135px;
		max-height: 135px;
		min-height: 90px;
		min-width: 90px;
	}
}

@media screen and (max-width: 960px) {
	.question-body-image {
		max-width: 85px;
		max-height: 85px;
		min-height: 60px;
		min-width: 60px;
	}
	.question-body-image-container {
		max-width: 400px;
		grid-gap: 5px;
		gap: 5px;
	}
}

@media screen and (max-width: 600px) {
	.question-body-image {
		max-width: 70px;
		max-height: 70px;
		min-height: 60px;
		min-width: 60px;
	}
	.question-body-image-container {
		max-width: 400px;
		grid-gap: 5px;
		gap: 5px;
	}
}

.recording-disclaimer-text {
    padding: 20px 20px 0px 20px;
    font-style: italic;
	font-size: 16px;
}
.recorder-container {
    width: 40%;
}

.btn-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
}

.record-btn {
    padding: 10px;
}

.stop-btn {
    padding: 10px;
}

.recorded-voice-container {
    display: flex;
    justify-content: center;
    width: -webkit-max-content;
    width: max-content;
}

.recorded-voice-container * {
    width: 100%;
}
.pre-room-page-container {
	display: flex;
	flex-direction: column;
	width: -webkit-max-content;
	width: max-content;
	grid-gap: 8px;
	gap: 8px;
	align-items: center;
	justify-content: center;
	box-sizing: border-box;
	height: 100%;
	width: 100%;
	padding: 24px;
	/* border-radius: 16px; */
	/* box-shadow: 0px 0px 20px 0px rgba(83, 83, 255, 0.5); */
}

.pre-room-page {
	color: white;
	background: gray;
	display: flex;
	position: relative;
	flex-direction: column;
	width: -webkit-max-content;
	width: max-content;
	justify-content: center;
	align-items: center;
	text-align: center;
	padding: 32px;
	margin: 0px 200px;
	margin-bottom: 150px;
}

.pre-room-welcome-text {
	max-width: 500px;
}

.pre-room-audio-test {
	width: 100%;
	margin: 10px;
}

.pre-room-question-form-btn {
	display: flex;
	justify-content: center;
}

.pre-room-questions {
	display: flex;
	flex-direction: column;
	justify-content: left;
}

.pre-room-question-answer {
	width: 100%;
}

.pre-room-audio-test {
	display: flex;
	justify-content: center;
}

.sound-test {
	display: grid;
	place-items: center;
	font-size: 16px;
	width: 100%;
}

.options {
	display: flex;
	justify-content: center;
	align-items: center;
	margin-bottom: 16px;
}

.feedback-page-container {
    height: 100%;
    width: 100%;
    padding: 24px;
    box-sizing: border-box;
    display: grid;
    place-items: center;
    overflow: hidden;
}

.feedback-screen {
    background: gray !important;
    color: white !important;
    position: relative;
    padding: 32px;
    max-width: 60vw !important;
    min-height: "150px";
	display: grid;
	place-items: center;
}

.feedback-controls {
    padding: 12px 0px 0px 0px;
    display: flex;
    justify-content: flex-end;
}

.feedback-submit {
    padding: 12px 0px 0px 0px;
    display: flex;
    justify-content: center;
}

.feedback-content > img {
    display: block;
    margin-left: auto;
    margin-right: auto;
    height: 300px;
	width: 300px;
}

.countdown {
    font-size: 36px;
    text-align: center;
}

.feedback-rating-stars {
    display: flex;
    justify-content: center;
    padding: 12px;
}

.feedback-text {
    display: flex;
    justify-content: center;
    padding: 18px;
    -webkit-text-fill-color: #fff;
}
#background-stars {
    position: fixed;
    right: 0;
    bottom: 0;
    min-width: 100%; 
    min-height: 100%;
}

.login-form {
    position: absolute;
    padding: 36px;
    background-color: gray !important;
    color: white !important;
    top: 40%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    width: -webkit-max-content;
    width: max-content;
    max-width: 500px;
    height: -webkit-max-content;
    height: max-content;
}

.privacy-text {
    padding: 20px 30px 0px 30px;
    font-size: 12px;
}

.form-fields {
    margin: 10px;
}
.homepage-container {
    /* box-shadow: 0px 0px 20px 0px rgb(0 200 247 / 50%);
    position: absolute;
    padding: 24px;
    border-radius: 16px;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    width: max-content;
    height: max-content;
    background-color: gray; */
    height: 100%;
    width: 100%;
}
#background-stars {
    position: fixed;
    right: 0;
    bottom: 0;
    min-width: 100%; 
    min-height: 100%;
}

.invalid-container {
    width: 100%;
    height: 100%;
    display: grid;
    place-items: center;
}
.App {
	height: 100vh;
	width: 100vw;
	overflow: hidden;
	font-size: larger;
}

#root {
	height: 100%;
	width: 100%;
}

a:link { text-decoration: none; }
a:visited {
	text-decoration: none; 
	color:blue;
}
a:hover { text-decoration: underline; }
a:active { text-decoration: none; }

div {
	white-space: pre-line;
}

.App-logo {
	height: 40vmin;
	pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
	.App-logo {
		-webkit-animation: App-logo-spin infinite 20s linear;
		        animation: App-logo-spin infinite 20s linear;
	}
}

.App-header {
	background-color: #282c34;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
}

.carla-box {
	position: absolute;
	top: 100%;
	right: 100%;
}

.carla-box > img {
	height: 200px;
	width: 200px;
}

.left-point {
	width: 0;
	height: 0;
	border-left: 2vh solid transparent;
	border-right: 2vh solid transparent;
	border-top: 10vh solid gray;
	position: absolute;
	top: calc(100% - 4.5vh);
	left: 10%;
	-webkit-transform: rotate(60deg);
	        transform: rotate(60deg);
}

.mic-change {
	margin: 12px;
	border-radius: 50%;
	height: 32px;
	width: 32px;
	cursor: pointer;
}

.register-form {
    position: absolute;
    padding: 24px;
    top: 40%;
    left: 50%;
    width: 400px;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    height: -webkit-max-content;
    height: max-content;
    background-color: gray !important;
    color: white !important;
}
.dangerous-div img {
	max-width: 200px;
	max-height: 200px;
}

.module-player {
    position: relative;
    display: grid;
}

.module-player>* {
    background-color: white;
    box-sizing: border-box;
}

.carla-speech-container {
    background: white;
    position: absolute;
    top: -100px;
    box-sizing: border-box;
    border-radius: 16px;
    border: solid 1px cyan;
    padding: 16px;
    box-shadow: 0px 0px 20px 0px rgba(83, 83, 255, 0.5);
}

.module-section-container {
    max-width: 60vw;
}

.screen {
    height: 100%;
    width: 100%;
    background-color: gray;
    color: white;
    padding: 32px;
    box-sizing: border-box;
}

.room-carla {
    bottom: -100px;
    top: auto;
}
#background-stars {
	position: fixed;
	right: 0;
	bottom: 0;
	min-width: 100%;
	min-height: 100%;
}

.room-box {
	display: grid;
	/* grid-template-columns: 1fr 2fr; */
	align-items: center;
	justify-items: center;
	padding: 24px;
	box-sizing: border-box;
	width: 100%;
}

.room-header {
	z-index: 100;
	height: 100%;
	background: gray;
	color: white;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 0px 35px;
}

.room-display-name {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.room-other-user > img {
	-webkit-filter: brightness(2) saturate(0.5);
	        filter: brightness(2) saturate(0.5);
}

.room-display-name > img {
	height: 50px;
	width: 50px;
}

.roompage-container {
	height: 100%;
	width: 100%;
	display: grid;
	grid-template-rows: 100px auto;
}

.call-controls {
	position: fixed;
	display: flex;
	z-index: 100;
	top: 20px;
	grid-gap: 8px;
	gap: 8px;
	place-items: center;
	box-sizing: border-box;
	left: 50%;
	-webkit-transform: translateX(-50%);
	        transform: translateX(-50%);
}

.call-controls {
	background-color: gray;
	color: white;
}

.room-box > * {
	width: -webkit-max-content;
	width: max-content;
}

#background-stars {
	position: fixed;
	right: 0;
	bottom: 0;
	min-width: 100%;
	min-height: 100%;
}

.room-counter {
	font-size: 64px;
	display: flex;
	justify-content: center;
}

.room-container {
	display: grid;
	width: 100%;
	height: 100%;
	place-items: center;
	padding: 24px;
	border-radius: 16px;
	box-sizing: border-box;
}

.room-page {
	max-width: 70%;
	background: gray;
	font-size: 16px;
	padding: 32px;
	position: relative;
	flex-direction: column;
	display: flex;
	align-items: center;
	grid-gap: 16px;
	gap: 16px;
	border-radius: 16px;
}

.randomText {
	color: white;
	text-align: center;
	width: 100%;
	font-size: 24px;
}

.waiting {
	height: 250px;
	width: 250px;
	border-radius: 16px;
	overflow: hidden;
}

.waiting > img {
	height: 100%;
	width: 100%;
}

@-webkit-keyframes breathe {
	0% {
		box-shadow: 0px 0px 20px 0px rgba(83, 83, 255, 0);
		-webkit-transform: scale(1) translate(-50%, -50%);
		        transform: scale(1) translate(-50%, -50%);
	}
	50% {
		box-shadow: 0px 0px 20px 0px rgba(83, 83, 255, 0.5);
		-webkit-transform: scale(1.005) translate(-50%, -50%);
		        transform: scale(1.005) translate(-50%, -50%);
	}
	100% {
		box-shadow: 0px 0px 20px 0px rgba(83, 83, 255, 0);
		-webkit-transform: scale(1) translate(-50%, -50%);
		        transform: scale(1) translate(-50%, -50%);
	}
}

@keyframes breathe {
	0% {
		box-shadow: 0px 0px 20px 0px rgba(83, 83, 255, 0);
		-webkit-transform: scale(1) translate(-50%, -50%);
		        transform: scale(1) translate(-50%, -50%);
	}
	50% {
		box-shadow: 0px 0px 20px 0px rgba(83, 83, 255, 0.5);
		-webkit-transform: scale(1.005) translate(-50%, -50%);
		        transform: scale(1.005) translate(-50%, -50%);
	}
	100% {
		box-shadow: 0px 0px 20px 0px rgba(83, 83, 255, 0);
		-webkit-transform: scale(1) translate(-50%, -50%);
		        transform: scale(1) translate(-50%, -50%);
	}
}

body {
  margin: 0;
  font-family: 'PT Mono', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  width: 100%;
}
html{
  height: 100%;
  width: 100%;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

