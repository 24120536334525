#background-stars {
    position: fixed;
    right: 0;
    bottom: 0;
    min-width: 100%; 
    min-height: 100%;
}

.login-form {
    position: absolute;
    padding: 36px;
    background-color: gray !important;
    color: white !important;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    width: max-content;
    max-width: 500px;
    height: max-content;
}

.privacy-text {
    padding: 20px 30px 0px 30px;
    font-size: 12px;
}

.form-fields {
    margin: 10px;
}