.register-form {
    position: absolute;
    padding: 24px;
    top: 40%;
    left: 50%;
    width: 400px;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    height: max-content;
    background-color: gray !important;
    color: white !important;
}