.recorder-container {
    width: 40%;
}

.btn-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
}

.record-btn {
    padding: 10px;
}

.stop-btn {
    padding: 10px;
}

.recorded-voice-container {
    display: flex;
    justify-content: center;
    width: max-content;
}

.recorded-voice-container * {
    width: 100%;
}